<template>
    <section>
<div class="tile is-ancestor">
  <div class="tile is-vertical is-8">
    <div class="tile">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child notification is-primary">
          <p class="title">Connect to your bank</p>
          <p class="subtitle">We make it so easy to connect to your bank</p>
        </article>
        <article class="tile is-child notification is-warning">
          <p class="title">Secure!</p>
          <p class="subtitle">We do not save your user name or password.</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child notification is-info">
          <p class="title">Safe</p>
          <p class="subtitle">We do not keep any of your private information otherthan bank account and transaction information.</p>
          <!-- <figure class="image is-4by3">
            <img src="https://bulma.io/images/placeholders/640x480.png">
          </figure> -->
        </article>
      </div>
    </div>
    <div class="tile is-parent">
      <article class="tile is-child notification is-success">
        <p class="title">Login to your bank to setup unlimited access to your bank transactions</p>
        <p class="subtitle">We do not save your user name or password</p>
        <div class="content">
            <a href="#" id='plaidlink' class="button" @click="openPlaidForm">Log in to Bank Account</a>

        </div>
      </article>
    </div>
  </div>
  <div class="tile is-parent">
    <article class="tile is-child notification is-default">
      <div class="content">
        <p class="title">Track your Deposits</p>
        <p class="subtitle"></p>
        <div class="content">
          Access all your bank accounts and transactions easily from a single application.
        </div>
      </div>
    </article>
  </div>
</div>

      <!-- <data-loading 
          :isLoading="isLoading" 
          :isFullPage="isFullPage"> 
      </data-loading> -->
    </section>
</template>

<script>
import UserStore from "../../store/UserStore"
// import DataLoading from '../../components/app/DataLoading'
import axios from 'axios'
import router from "./../../router"

export default {

    components: {
      // DataLoading,
    },

    data() {
        return {
            isLoading: false,
            isFullPage: true,
            user: [],
            plaid: null,
            plaidClientName: 'VEECLi',
            plaidEnv: 'production',
            plaidPublicKey: '5a6d1ba3cb0abefdbaf33536dbe14d',
            plaidProduct: ['auth','transactions'],
            plaidCountryCodes: ['US']
            
        }
    },

    methods: {
        openPlaidForm() {

            if (this.plaid != null) { 
                console.log('Plaid not empty', this.plaid)
                var self = this
                this.plaid.create({
                    clientName: this.plaidClientName,
                    env: this.plaidEnv,
                    key: this.plaidPublicKey,
                    product: this.plaidProduct,
                    countryCode: this.plaidCountryCodes,
                    onLoad: () => {
                        self.onLoad()
                    },
                    onSuccess: (public_token, metadata) => {
                        self.onSuccess(public_token, metadata)
                    },
                    onExit: (err, metadata) => {
                        self.onExit(err, metadata)
                    },
                    onEvent: (eventName, metadata) => {
                        self.onEvent(eventName, metadata)
                    }

                }).open()
            } else {
                console.log('Plaid is empty')
            }

        },

        onLoad() {

        },

        onSuccess(public_token, metadata) {

          this.isLoading = true

          var bank = {
            institutionId: metadata.institution.institution_id,
            institutionName: metadata.institution.name,
            publicToken: metadata.public_token
          }

          if (this.user.activeStore) {

              const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                          + '/banking'
              axios.post(url,
                  bank,
                  {
                      headers: {
                          'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                          'Refresh-Token': UserStore.getters.getRefreshToken
                      },                   
                  })
                  .then( () => {
                    this.isLoading = false
                    router.push({ path: '/store/banks/accounts' })
                  })
                  .catch( (err) => {
                      if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                      this.isLoading = false
                      if (err.response.status === 403) {
                          alert('Session Expired. Please log in again!')
                          UserStore.commit('userLogout')
                      }
                  })
              this.isLoading = false                  
          }
        },

        onExit() {
            this.$buefy.notification.open({
                    message: 'Failed to save bank information. Please contact support@veecli.com!',
                    type: 'is-danger',
                    duration: 6000,
                    'has-icon': true,
                    'icon-pack': 'fas',
                    icon: 'exclamation-circle'
                })  
        },

        onEvent(eventName, metadata) {
            console.log('event', eventName, metadata)
        },
    },

    mounted() {
      this.user = UserStore.getters.getUser
      if (UserStore.getters.getCurrentHost != "app.veecli.com") {
        this.plaidEnv="development"
      }

      console.log("Plaid Config",UserStore.getters.getCurrentHost,  this.plaidEnv)

      let initPlaid = document.createElement('script')
      initPlaid.async = true
      initPlaid.setAttribute('src', 'https://cdn.plaid.com/link/v2/stable/link-initialize.js')
      document.head.appendChild(initPlaid)

      initPlaid.onload = () => {
          this.plaid = window.Plaid
      }
    },    
    
}
</script>